import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"

class ConsultPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>失眠咨询</title>
          </Helmet>
          
        </div>
      </Layout>
    )
  }
}

export default ConsultPage
